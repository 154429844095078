var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h6", { staticClass: "title" }, [
              _vm._v("Chi tiết đơn hàng: "),
              _c("b", { staticClass: "pull-right" }, [
                _vm._v(_vm._s(_vm.form.id)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "card-body order-title" }, [
            _c("div", { staticClass: "right" }, [
              _c("p", [
                _vm._v("Ngày tạo: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.$util.dateTimeFormat(_vm.form.created_at))),
                ]),
              ]),
              _c("p", [
                _vm._v("Ngày cập nhật: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.$util.dateTimeFormat(_vm.form.updated_at))),
                ]),
              ]),
              _c("p", [
                _vm._v("Tên gói: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.form.package_name)),
                ]),
              ]),
              _c("p", [
                _vm._v("Thời gian: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.form.number_times) + " tháng"),
                ]),
              ]),
              _c("p", [
                _vm._v("Số người: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.form.number_users)),
                ]),
              ]),
              _c("p", [
                _vm._v("Ngày bắt đầu: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.$util.dateTimeFormat(_vm.form.start_at))),
                ]),
              ]),
              _c("p", [
                _vm._v("Ngày kết thúc: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.$util.dateTimeFormat(_vm.form.end_at))),
                ]),
              ]),
            ]),
            _c("div", [
              _c("br"),
              _c("p", [
                _vm._v("Số tiền: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.$util.formatMoney(_vm.form.total_payment))),
                ]),
              ]),
              _c("p", [
                _vm._v("Trạng thái:\n            "),
                _vm.form.status
                  ? _c("b", { staticClass: "badge badge-success pull-right" }, [
                      _vm._v("Đã thanh toán"),
                    ])
                  : _c("b", { staticClass: "badge badge-danger pull-right" }, [
                      _vm._v("Chưa thanh toán"),
                    ]),
              ]),
              _c("p", [
                _vm._v("Ghi chú: "),
                _c("b", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(_vm.form.notes || "Không có")),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm.form.payment_name
          ? _c("div", { staticClass: "card" }, [
              _vm._m(0),
              _c("div", { staticClass: "card-body order-title" }, [
                _c("div", [
                  _c("p", [
                    _vm._v("Cổng thanh toán: "),
                    _c("b", { staticClass: "pull-right" }, [
                      _vm._v(_vm._s(_vm.form.paygate_name)),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v("Hình thức thanh toán: "),
                    _c("b", { staticClass: "pull-right" }, [
                      _vm._v(_vm._s(_vm.form.payment_name)),
                    ]),
                  ]),
                  _vm.form.status_format == "Chưa thanh toán"
                    ? _c("p", [
                        _vm._v("Trạng thái thanh toán: "),
                        _c(
                          "b",
                          { staticClass: "badge badge-danger pull-right" },
                          [_vm._v(_vm._s(_vm.form.status_format))]
                        ),
                      ])
                    : _vm._e(),
                  _vm.form.payment == 5 &&
                  _vm.form.status == 0 &&
                  _vm.expiredTimeBankTransfer
                    ? _c("div", [
                        _c(
                          "div",
                          { staticStyle: { color: "red", float: "right" } },
                          [_vm._v("Đã hết hạn thanh toán")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.form.payment_transaction_id
                    ? _c("div", [
                        _c("p", [
                          _vm._v("Mã giao dịch: "),
                          _c("b", [
                            _vm._v(_vm._s(_vm.form.payment_transaction_id)),
                          ]),
                        ]),
                        _vm.form.payment_logs
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.form.payment_logs,
                                function (value, key) {
                                  return _c("p", [
                                    _c(
                                      "span",
                                      { staticClass: "payment-log-item" },
                                      [_vm._v(_vm._s(key))]
                                    ),
                                    _vm._v(": "),
                                    _c("b", [_vm._v(_vm._s(value))]),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h6", { staticClass: "title" }, [
              _vm._v("Thông tin khách hàng\n          "),
              _c(
                "a",
                {
                  staticClass: "pull-right",
                  attrs: { href: `/#/users/${_vm.form.user_id}` },
                },
                [_vm._v(_vm._s(_vm.form.user_id))]
              ),
            ]),
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "full-width",
                    class: _vm.errors.has("name") ? "border-danger" : "",
                    attrs: {
                      type: "text",
                      placeholder: "Tên đầy đủ",
                      "data-vv-name": "name",
                      "data-vv-as": "Tên đầy đủ",
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _vm.errors.has("name")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("name"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "full-width",
                    class: _vm.errors.has("first_name") ? "border-danger" : "",
                    attrs: {
                      type: "text",
                      placeholder: "Tên",
                      "data-vv-name": "first_name",
                      "data-vv-as": "Tên",
                    },
                    model: {
                      value: _vm.form.first_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "first_name", $$v)
                      },
                      expression: "form.first_name",
                    },
                  }),
                  _vm.errors.has("first_name")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("first_name"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(3),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "full-width",
                    class: _vm.errors.has("last_name") ? "border-danger" : "",
                    attrs: {
                      type: "text",
                      placeholder: "Họ",
                      "data-vv-name": "last_name",
                      "data-vv-as": "Họ",
                    },
                    model: {
                      value: _vm.form.last_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "last_name", $$v)
                      },
                      expression: "form.last_name",
                    },
                  }),
                  _vm.errors.has("last_name")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("last_name"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(4),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "full-width",
                    class: _vm.errors.has("email") ? "border-danger" : "",
                    attrs: {
                      type: "text",
                      placeholder: "Email",
                      "data-vv-name": "email",
                      "data-vv-as": "Email",
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                  _vm.errors.has("email")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("email"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(5),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("el-input", {
                    staticClass: "full-width",
                    attrs: { type: "text", placeholder: "Điện thoại" },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(6),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "full-width",
                      attrs: { placeholder: "Giới tính" },
                      model: {
                        value: _vm.form.gender,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "gender", $$v)
                        },
                        expression: "form.gender",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "Nam", value: "1" } }),
                      _c("el-option", { attrs: { label: "Nữ", value: "0" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(7),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("el-date-picker", {
                    staticClass: "full-width",
                    attrs: { type: "date", placeholder: "Ngày sinh" },
                    model: {
                      value: _vm.form.birthday,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "birthday", $$v)
                      },
                      expression: "form.birthday",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(8),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "full-width",
                      attrs: { placeholder: "Tỉnh/thành phố" },
                      model: {
                        value: _vm.form.province,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "province", $$v)
                        },
                        expression: "form.province",
                      },
                    },
                    _vm._l(_vm.provinces, function (option) {
                      return _c("el-option", {
                        key: option.id,
                        attrs: { label: option.name, value: option.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(9),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "full-width",
                      attrs: { placeholder: "Quận/huyện" },
                      model: {
                        value: _vm.form.district,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "district", $$v)
                        },
                        expression: "form.district",
                      },
                    },
                    _vm._l(_vm.districts, function (option) {
                      return _c("el-option", {
                        key: option.id,
                        attrs: { label: option.name, value: option.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(10),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "full-width",
                      attrs: { placeholder: "Phường/xã" },
                      model: {
                        value: _vm.form.ward,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "ward", $$v)
                        },
                        expression: "form.ward",
                      },
                    },
                    _vm._l(_vm.wards, function (option) {
                      return _c("el-option", {
                        key: option.id,
                        attrs: { label: option.name, value: option.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(11),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "full-width",
                    class: _vm.errors.has("address") ? "border-danger" : "",
                    attrs: {
                      type: "text",
                      placeholder: "Địa chỉ",
                      "data-vv-name": "address",
                      "data-vv-as": "Địa chỉ",
                    },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                  _vm.errors.has("address")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("address"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _vm._m(12),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _vm._m(13),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("my-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "full-width",
                    class: _vm.errors.has("status") ? "border-danger" : "",
                    attrs: {
                      attribute: _vm.listStatus,
                      placeholder: "Trạng thái thanh toán...",
                      "data-vv-name": "status",
                      "data-vv-as": "Trạng thái thanh toán",
                    },
                    model: {
                      value: _vm.form.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status",
                    },
                  }),
                  _vm.errors.has("status")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("status"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(14),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("el-input", {
                    staticClass: "full-width notes",
                    attrs: { type: "textarea", placeholder: "Ghi chú" },
                    model: {
                      value: _vm.form.notes,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "notes", $$v)
                      },
                      expression: "form.notes",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c("loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function ($event) {
            _vm.loading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", { staticClass: "title" }, [_vm._v("Cổng thanh toán")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Tên đầy đủ:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Tên:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Họ:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Email:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", [_vm._v("Điện thoại:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Giới tính:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", [_vm._v("Ngày sinh:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Tỉnh/TP:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Quận/huyện:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Phường/xã:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Địa chỉ:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", { staticClass: "title" }, [_vm._v("Thông tin khác")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", { staticClass: "required" }, [_vm._v("Trạng thái thanh toán:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("p", [_vm._v("Ghi chú:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }